$(window).on('load', function() {

  var d = new Date();
  var n = d.getHours();
  if(n > 22 || n < 7){
    $('html').addClass('night');
  }

  var $body = $('body');
  var $container = $('.container');
  var currentFont = '';
  var timeout;
  $('.paginator a').click(function(e){
    $(this).closest('ul').find('a').removeClass('active');
    currentFont = $(this).attr('class');
    $(this).addClass('active');

    var $content = $('.text-body');
    var $newContent = $content.clone().attr('class', 'text-body ' + currentFont);

    $content.after($newContent);
    $content.removeClass('active');
    $newContent.addClass('active');

    clearTimeout(timeout);
    timeout = setTimeout(function(){
      $('.text-body:not(.active)').remove();
    }, 200);

    e.preventDefault();
  });

  var elements =  $('.paginator a').length;


  $("body").swipe( {
    threshold: 100,
    swipeLeft: function(event, direction, distance, duration, fingerCount, fingerData){
      var index = $('.paginator a.active').parent().index();
      if(index + 1 >= elements){
        $('.paginator li:first-child a').parent().find('a').trigger('click');
      } else {
        $('.paginator a.active').parent().next().find('a').trigger('click');
      }
    },
    swipeRight: function(event, direction, distance, duration, fingerCount, fingerData){

      var index = $('.paginator a.active').parent().index();
      if(index < 1){
        $('.paginator li:last-child a').parent().find('a').trigger('click');
      } else {
        $('.paginator a.active').parent().prev().find('a').trigger('click');
      }

    }
  });



  $('html').addClass('html-loaded');

});